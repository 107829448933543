/**
 * @file 空中课堂
 */
// api
import { getCrossCourseAddress } from '@/api/authority-manage';

// component
import basicHeader from '@/components/backStageHeader';
import CalenderComponent from './components/calender';
import ListGroupComponent from './components/listGroup';
import ConfigComponent from './components/config';

export default {
  components: {
    basicHeader,
    CalenderComponent,
    ListGroupComponent,
    ConfigComponent,
  },
  data() {
    return {
      isLoading: true,

      activeTab: 2,
      tabs: [
        {
          id: 0,
          name: '课程表',
          hidden: false,
        },
        {
          id: 1,
          name: '结对组',
          hidden: false,
        },
        {
          id: 2,
          name: '空中课堂配置',
          hidden: false,
        },
      ],
      type: undefined,
    };
  },
  computed: {
    showTabs() {
      return this.tabs.filter((x) => !x.hidden);
    },
  },
  methods: {
    // handle 切换tag
    handleTag(val) {
      this.activeTab = val.id;
    },
    resetConfig() {
      this.getData();
    },
    getData() {
      this.isLoading = true;

      getCrossCourseAddress()
        .then((res) => {
          const setDefaultTab = () => {
            this.tabs[0].hidden = true;
            this.tabs[1].hidden = true;
            this.activeTab = 2;
          };

          if (res.status === 1) {
            // 暂无空中课堂
            setDefaultTab();
          } else {
            switch (res.result.type) {
              case 0:
                // 暂无空中课堂
                setDefaultTab();
                break;
              case 1: // 使用青鹿临时开课功能
              case 2: // 接入AVA专递课堂功能
              default:
                this.tabs[0].hidden = false;
                this.tabs[1].hidden = false;
                this.activeTab = 0;
                break;
            }

            this.type = res.result.type;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
