<template>
  <div>
    <div class="flex items-center mg-t-24 mg-b-24">
      <div class="before-title"></div>
      <div class="title">空中课堂配置</div>
    </div>
    <div class="mg-l-10 mg-b-20">
      <el-radio-group v-model="activeConfig">
        <el-radio v-for="item in config" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
      </el-radio-group>
    </div>
    <div v-if="activeConfig !== undefined" style="margin-top: 60px">
      <el-button size="small" type="primary" @click="onSubmit">保 存</el-button>
    </div>
  </div>
</template>

<script>
import { saveCrossCourseAddress } from '@/api/authority-manage';
import { RadioGroup, Radio, Input, Button } from 'element-ui';

export default {
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  props: {
    type: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  data() {
    return {
      config: [
        {
          id: 0,
          name: '暂无空中课堂',
        },
        {
          id: 1,
          name: '使用青鹿临时开课功能',
        },
      ],
      activeConfig: undefined,
    };
  },
  methods: {
    handleChangeConfig(val) {
      this.activeConfig = val.id;
    },
    onSubmit() {
      saveCrossCourseAddress({
        type: this.activeConfig,
      }).then(() => {
        this.$message.success('保存成功');
        this.$emit('reload');
      });
    },
  },
  watch: {
    type: {
      handler: function () {
        this.activeConfig = this.type;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.before-title {
  width: 4px;
  height: 16px;
  background: $themeColor;
  border-radius: 2px;
}
.title {
  font-size: 16px;
  font-weight: 600;
  color: #2c2f2e;
  line-height: 24px;
  margin-left: 8px;
}
</style>
