<template>
  <div class="calender" v-loading="isLoading">
    <div class="flex justify-center relative mg-t-24 mg-b-20">
      <div class="fz-18" style="font-weight: 600; color: #2c2f2e; line-height: 32px">空中课堂课程表</div>
      <div class="absolute right-0 top-0">
        <el-date-picker
          v-model="timePickerDate"
          type="date"
          placeholder="选择日期"
          size="small"
          :clearable="false"
          style="width: 150px"
          @change="handleDatePickerChange"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="calender-container">
      <div class="calender-header">
        <i class="cursor-pointer el-icon-arrow-left" @click="handleDateChange('subtract')"></i>
        <div
          v-for="item in dateList"
          :key="item.weekName"
          class="item"
          :class="{ active: isSameDay(item.time, currentDate) }"
          @click="handleSelect(item.time)"
        >
          <div class="time">{{ item.weekName }}</div>
          <div class="date">
            {{ item.timeName }}
            <div v-if="item.num" class="num">{{ item.num }}</div>
          </div>
        </div>
        <i class="cursor-pointer el-icon-arrow-right" @click="handleDateChange('add')"></i>
      </div>
      <div class="calender-list">
        <div
          class="list-tab"
          :class="{ 'list-tab-status-finish': item.courseStatus === 3 && !item.wrongTypeId }"
          v-for="item in courseClassList"
          :key="item.id"
        >
          <div class="list-tab-header">
            <div class="list-tab-header-item">
              <div class="list-tab-header-item-label">课程名称：</div>
              <div class="list-tab-header-item-content">{{ item.name }}</div>
            </div>
            <div class="list-tab-header-item">
              <div class="list-tab-header-item-label">学科：</div>
              <div class="list-tab-header-item-content">{{ item.lessonName }}</div>
            </div>
            <div class="list-tab-header-item">
              <div class="list-tab-header-item-label">老师：</div>
              <div class="list-tab-header-item-content">{{ item.teacherName }}</div>
            </div>
            <div v-if="item.wrongTypeId" class="list-tab-header-tag fail">同步失败</div>
            <div v-if="item.courseStatus === 1 && !item.wrongTypeId" class="list-tab-header-tag ready">未开始</div>
            <div v-if="item.courseStatus === 2 && !item.wrongTypeId" class="list-tab-header-tag success">进行中</div>
            <div v-if="item.courseStatus === 3 && !item.wrongTypeId" class="list-tab-header-tag ready">已结束</div>
          </div>
          <div class="list-tab-container">
            <div v-if="!item.wrongTypeId" class="col">
              <div class="col-label">类型</div>
              <div class="col-content">
                <div v-for="(item, index) in item.courseClassList" :key="index">
                  <div v-if="index === 0" class="mg-b-8 leading-none">主讲</div>
                  <div v-else class="mg-b-8 leading-none">接收{{ index }}</div>
                </div>
              </div>
            </div>
            <div v-if="!item.wrongTypeId" class="col">
              <div class="col-label">学校</div>
              <div class="col-content">
                <div v-for="(x, index) in item.courseClassList" :key="index">
                  <div class="mg-b-8 leading-none">{{ x.schoolName }}</div>
                </div>
              </div>
            </div>
            <div v-if="!item.wrongTypeId" class="col">
              <div class="col-label">教室</div>
              <div class="col-content">
                <div v-for="(x, index) in item.courseClassList" :key="index">
                  <div class="mg-b-8 leading-none">{{ x.classromName }}</div>
                </div>
              </div>
            </div>
            <div v-if="!item.wrongTypeId" class="col">
              <div class="col-label">班级</div>
              <div class="col-content">
                <div v-for="(x, index) in item.courseClassList" :key="index">
                  <div class="mg-b-8 leading-none">{{ x.className }}</div>
                </div>
              </div>
            </div>
            <div v-if="item.wrongTypeId" class="col-fail">
              <div class="col-fail-msg">数据同步失败，请检查专题课堂中课表字段是否与后台管理数据一致</div>
              <el-button type="primary" @click="handleFail">点击进入</el-button>
            </div>
            <div class="col" style="padding-left: 70px; border-left: 1px dashed #dee0e3">
              <div class="col-label">上课时间</div>
              <div class="col-content col-content-time">{{ item.startTime }}-{{ item.endTime }}</div>
            </div>
          </div>
        </div>
        <div v-if="courseClassList.length === 0">
          <noData></noData>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
// component
import { Button, DatePicker } from 'element-ui';
import noData from '@/components/noData/index.vue';
// api
import { getCourseListGroupByDate } from '@/api/authority-manage';
import { downloadFileByA } from '@/utils/common';

export default {
  components: {
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    noData,
  },

  data() {
    return {
      isLoading: false,

      dateList: [
        // {
        //   time: 'yyyy-MM-dd',
        //   weekName: '星期日',
        //   num: 0,
        // },
      ],
      courseClassList: [],

      timePickerDate: undefined, // DatePicker 组件value

      currentDate: '', // 当前日期 Date()
      resultData: [], // 接口返回数据
    };
  },
  methods: {
    // handle 选择日期
    async handleDatePickerChange() {
      this.currentDate = dayjs(this.timePickerDate).format('YYYY-MM-DD');
      this.setDateList(this.currentDate);
      await this.getData();
    },
    // handle 点击选中日期
    handleSelect(date) {
      this.currentDate = dayjs(date).format('YYYY-MM-DD');
      this.timePickerDate = dayjs(this.currentDate);
      this.setCourseClassList();
    },
    // handle 切换周
    async handleDateChange(type) {
      this.currentDate = dayjs(this.currentDate)[type](7, 'day').format('YYYY-MM-DD');
      this.timePickerDate = dayjs(this.currentDate);
      this.setDateList(this.currentDate);
      await this.getData();
    },
    // handle fail
    handleFail(item) {
      downloadFileByA(item.remoteUrl);
    },
    // util 设置日期列表
    setDateList(time) {
      let now = time ? new Date(time) : new Date();
      let day = now.getDay();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let dateList = Object.keys(Array.apply(null, { length: 7 }));
      let monDateTime = now.getTime() - day * oneDayTime;

      dateList = dateList.map((item) => {
        let weekday = monDateTime + +item * oneDayTime;
        let d = new Date(weekday);
        let weekName = '';

        switch (item) {
          case '0':
            weekName = '星期日';
            break;
          case '1':
            weekName = '星期一';
            break;
          case '2':
            weekName = '星期二';
            break;
          case '3':
            weekName = '星期三';
            break;
          case '4':
            weekName = '星期四';
            break;
          case '5':
            weekName = '星期五';
            break;
          case '6':
            weekName = '星期六';
            break;
        }
        let month = d.getMonth() + 1 + '';
        let date = d.getDate() + '';

        month = (month.length < 2 ? '0' : '') + month;
        date = (date.length < 2 ? '0' : '') + date;

        return {
          time: `${d.getFullYear()}-${month}-${date}`,
          timeName: `${month}-${date}`,
          weekName: weekName,
          num: 0,
        };
      });

      this.dateList = dateList;
      return dateList;
    },
    // util 设置课程列表
    setCourseClassList() {
      let item = this.resultData.find((x) => x.date === this.currentDate);
      if (item) {
        this.courseClassList = item.courseClassList;
      } else {
        this.courseClassList = [];
      }
    },
    // api 查询学校的空中课堂课表列表
    getData() {
      this.isLoading = true;

      return getCourseListGroupByDate({
        startTime: `${this.dateList[0].time} 00:00:00`,
        endTime: `${this.dateList[this.dateList.length - 1].time} 23:59:59`,
      })
        .then(({ result }) => {
          this.resultData = result;
          // set num
          this.dateList.map((x) => {
            let item = this.resultData.find((y) => y.date === x.time);
            x.num = item?.courseClassList.length || 0;
          });
          this.setCourseClassList();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // util 判断是否同一天
    isSameDay(date1, date2) {
      return dayjs(date1).isSame(dayjs(date2));
    },
    init() {
      this.setDateList();
      this.currentDate = dayjs(new Date()).format('YYYY-MM-DD');
      this.timePickerDate = dayjs(this.currentDate);
      this.getData();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.calender {
  display: flex;
  flex-direction: column;

  ::v-deep .el-icon-date {
    color: #6d7171;
    font-size: 16px;
  }

  &-container {
    border-radius: 4px;
    border: 1px solid #dee0e3;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1px solid #dee0e3;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
      cursor: pointer;
      border-bottom: 2px solid #fff;

      &:hover,
      &.active {
        border-bottom: 2px solid $themeColor;

        .time,
        .date {
          color: $themeColor;
        }
      }

      .time {
        font-size: 14px;
        font-weight: 400;
        color: #8b8f8f;
        line-height: 20px;
      }

      .date {
        font-size: 16px;
        font-weight: 600;
        color: #2c2f2e;
        line-height: 22px;
        margin-top: 6px;
        position: relative;

        .num {
          position: absolute;
          top: 11px;
          right: -6px;
          transform: translateY(-50%) translateX(100%);
          background-color: $themeColor;
          border-radius: 10px;
          color: #fff;
          display: inline-block;
          font-size: 12px;
          height: 18px;
          line-height: 18px;
          padding: 0 5px;
          text-align: center;
          white-space: nowrap;
          border: 1px solid #fff;
        }
      }
    }
  }
  &-list {
    padding: 25px;
    min-height: 60vh;

    .list-tab {
      border: 1px solid #dee0e3;
      margin-bottom: 24px;

      &-status-finish {
        opacity: 0.5;
      }

      &-header {
        height: 48px;
        background: #f6f8fb;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 24px 0 32px;

        &-item {
          display: flex;
          width: 300px;

          &-label {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #8b8f8f;
          }
          &-content {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #2c2f2e;
          }
        }
        &-tag {
          position: absolute;
          right: 24px;
          top: 12px;
          display: flex;
          align-items: center;
          border-radius: 12px;
          padding: 0 12px;

          &.success {
            color: $themeColor;
            background: #ebfffa;
            border: 1px solid $themeColor;
          }
          &.ready {
            color: #adadad;
            border: 1px solid #adadad;
          }
          &.fail {
            color: #e95156;
            background: #fff0f0;
            border: 1px solid #e95156;
          }
        }
      }
      &-container {
        padding: 24px 32px;
        display: flex;

        .col {
          width: 300px;

          .col-label {
            font-size: 14px;
            font-weight: 400;
            color: #8b8f8f;
            line-height: 20px;
            margin-bottom: 8px;
          }
          .col-content {
            display: flex;
            flex-direction: column;
          }
          .col-content-time {
            display: flex;
            justify-content: center;
            height: 63px;

            font-size: 20px;
            font-weight: 600;
            color: #2c2f2e;
            line-height: 28px;
          }
        }
        .col-fail {
          width: 1200px;

          &-msg {
            color: #e95156;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>
