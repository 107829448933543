<template>
  <div class="ql-empty">
    <img alt="empty" src="@images/components/noData/noData.png" />
    <p class="mg-t-15">暂无内容，空空如也~</p>
  </div>
</template>

<script>
export default {
  name: 'ql-empty',
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
