<template>
  <div>
    <div class="flex items-center justify-between mg-t-24 mg-b-24">
      <div class="title">空中课堂结对组</div>
      <el-button type="primary" size="small" @click="handleAdd">
        <i class="el-icon-plus mg-r-4"></i>
        创建结对组
      </el-button>
    </div>
    <div>
      <el-table :data="tableRender" style="width: 100%">
        <el-table-column label="主讲教室">
          <template slot-scope="scope">
            <div class="flex flex-col leading-none" style="color: #2c2f2e">
              <span>{{ scope.row.schoolName || '' }}</span>
              <span class="mg-t-6">{{ scope.row.classRoomName || '' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="听讲教室1">
          <template slot-scope="scope">
            <div class="flex flex-col leading-none" style="color: #8b8f8f">
              <span>{{ scope.row.lectureRoomsList[0].schoolName || '' }}</span>
              <span class="mg-t-6">{{ scope.row.lectureRoomsList[0].classRoomName || '' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mobileNo" label="听讲教室2">
          <template slot-scope="scope">
            <div class="flex flex-col leading-none" style="color: #8b8f8f">
              <span>{{ scope.row.lectureRoomsList[1] ? scope.row.lectureRoomsList[1].schoolName || '' : '' }}</span>
              <span class="mg-t-6">{{
                scope.row.lectureRoomsList[1] ? scope.row.lectureRoomsList[1].classRoomName || '' : ''
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="teacherNo" label="听讲教室3">
          <template slot-scope="scope">
            <div class="flex flex-col leading-none" style="color: #8b8f8f">
              <span>{{ scope.row.lectureRoomsList[2] ? scope.row.lectureRoomsList[2].schoolName || '' : '' }}</span>
              <span class="mg-t-6">{{
                scope.row.lectureRoomsList[2] ? scope.row.lectureRoomsList[2].classRoomName || '' : ''
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row, scope.$index)">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row, scope.$index)" del>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <qlPagination
        :current-page.sync="table.pageIndex"
        :page-size="table.pageSize"
        :total="table.total"
        @size-change="
          (val) => {
            table.pageSize = val;
            table.pageIndex = 1;
            getData();
          }
        "
        @current-change="
          (val) => {
            table.pageIndex = val;
            getData();
          }
        "
      ></qlPagination>
    </div>
    <listGroupDialog
      :visible="dialog.visible"
      :optionType="dialog.optionType"
      :pairGroupId="dialog.pairGroupId"
      :editParams="dialog.editParams"
      @close="dialog.visible = false"
      @success="
        () => {
          dialog.visible = false;
          getData();
        }
      "
    ></listGroupDialog>
  </div>
</template>

<script>
import { getListPairGroup, deletePairGroup } from '@/api/authority-manage';
import { Table, TableColumn, Button } from 'element-ui';
import qlPagination from '@/components/qlPagination';
import listGroupDialog from './listGroupDialog';

import { DIALOG_TYPE_ADD, DIALOG_TYPE_EDIT } from '../constance';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    qlPagination,
    listGroupDialog,
  },
  data() {
    return {
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
      dialog: {
        visible: false,
        optionType: DIALOG_TYPE_ADD,
        pairGroupId: '',
        editParams: {},
      },
    };
  },
  computed: {
    tableRender() {
      return this.table.list.map((item) => {
        if (item.classRoomDeleted === 1) {
          item.classRoomName = '该教室已被删除';
        }

        item.lectureRoomsList.map((room) => {
          if (room.classRoomDeleted == 1) {
            room.classRoomName = '该教室已被删除';
          }
        });

        return item;
      });
    },
  },
  methods: {
    handleAdd() {
      this.dialog.optionType = DIALOG_TYPE_ADD;
      this.dialog.pairGroupId = '';
      this.dialog.visible = true;
    },
    handleEdit(val) {
      this.dialog.optionType = DIALOG_TYPE_EDIT;
      this.dialog.visible = true;
      this.dialog.pairGroupId = String(val.pairGroupId);
      this.dialog.editParams = {
        classRoomId: val.classRoomId,
        listeningSchoolId1: val.lectureRoomsList[0]?.schoolId ?? '',
        listeningSchoolName1: val.lectureRoomsList[0]?.schoolName ?? '',
        listeningClassroomId1: val.lectureRoomsList[0]?.classRoomId ?? '',
        listeningSchoolId2: val?.lectureRoomsList[1]?.schoolId ?? '',
        listeningSchoolName2: val.lectureRoomsList[1]?.schoolName ?? '',
        listeningClassroomId2: val?.lectureRoomsList[1]?.classRoomId ?? '',
        listeningSchoolId3: val?.lectureRoomsList[2]?.schoolId ?? '',
        listeningSchoolName3: val.lectureRoomsList[2]?.schoolName ?? '',
        listeningClassroomId3: val.lectureRoomsList[2]?.classRoomId ?? '',
      };
    },
    handleDialogSuccess() {
      this.dialog.visible = false;
      this.getData();
    },
    handleDialogCancel() {
      this.dialog.visible = false;
    },
    handleDelete(val) {
      this.$msgbox
        .confirm(`确认删除该结对组吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deletePairGroup({
            pairGroupId: val.pairGroupId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已成功删除结对组!`,
            });
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getData() {
      getListPairGroup({
        pageSize: this.table.pageSize,
        pageIndex: this.table.pageIndex,
      }).then(({ result }) => {
        if (result.data.length === 0 && this.table.pageIndex !== 1) {
          this.table.pageIndex--;
          this.getData();
          return;
        }

        this.table.total = result.recordCount;
        this.table.list = result.data;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: 600;
  color: #2c2f2e;
  line-height: 24px;
}

::v-deep .el-button:focus {
  outline: none;
}
</style>
