<template>
  <el-dialog
    custom-class="ql-dialog "
    :title="typeWord + '结对组'"
    :visible.sync="dialogVisible"
    width="596px"
    @close="handleCancel"
  >
    <el-form v-loading="isLoading" ref="form" :model="form" :rules="rules" label-width="112px">
      <el-form-item label="我校主讲教室" prop="classRoomId" size="small">
        <el-select
          v-model="form.classRoomId"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请选择"
          style="width: 100%"
          filterable
          clearable
        >
          <el-option
            v-for="item in ourSchoolLectureRoom"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="听讲学校1" prop="listeningSchool1" size="small">
        <el-select
          v-model="form.listeningSchoolId1"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请输入关键字"
          style="width: 100%"
          filterable
          remote
          :remote-method="remoteMethod1"
          @change="getListeningClassrom1"
        >
          <el-option
            v-for="item in listeningSchool1"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="听讲教室1" prop="listeningClassroom1" size="small">
        <el-select
          v-model="form.listeningClassroomId1"
          :disabled="!form.listeningSchoolId1"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请选择"
          style="width: 100%"
          filterable
          clearable
        >
          <el-option v-for="item in listeningClassroom1" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="听讲学校2" prop="listeningSchool2" size="small">
        <el-select
          v-model="form.listeningSchoolId2"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请输入关键字"
          style="width: 100%"
          filterable
          remote
          :remote-method="remoteMethod2"
          @change="getListeningClassrom2"
        >
          <el-option
            v-for="item in listeningSchool2"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="听讲教室2" prop="listeningClassroom2" size="small">
        <el-select
          v-model="form.listeningClassroomId2"
          :disabled="!form.listeningSchoolId2"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请选择"
          style="width: 100%"
          filterable
          clearable
        >
          <el-option v-for="item in listeningClassroom2" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="听讲学校3" prop="listeningSchool3" size="small">
        <el-select
          v-model="form.listeningSchoolId3"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请输入关键字"
          style="width: 100%"
          filterable
          remote
          :remote-method="remoteMethod3"
          @change="getListeningClassrom3"
        >
          <el-option
            v-for="item in listeningSchool3"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="听讲教室3" prop="listeningClassroom3" size="small">
        <el-select
          v-model="form.listeningClassroomId3"
          :disabled="!form.listeningSchoolId3"
          class="listGroup-dialog-select"
          popper-class="listGroup-dialog-select-popper"
          placeholder="请选择"
          style="width: 100%"
          filterable
          clearable
        >
          <el-option v-for="item in listeningClassroom3" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleSave">{{ typeWord }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getListSchool, getListClassrom, saveOrUpdatePairGroup } from '@/api/authority-manage';
import { Dialog, Form, FormItem, Button, Input, Select, Option } from 'element-ui';

import { DIALOG_TYPE_ADD, DIALOG_TYPE_EDIT } from '../constance';

export default {
  name: 'listGroupDialog',
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    optionType: {
      type: Number,
      required: true,
      default: DIALOG_TYPE_ADD,
    },
    pairGroupId: {
      type: String,
      required: false,
    },
    editParams: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      DIALOG_TYPE_ADD,
      DIALOG_TYPE_EDIT,

      dialogVisible: false,
      isLoading: false,

      form: {
        classRoomId: '',
        listeningSchoolId1: '',
        listeningSchoolName1: '',
        listeningClassroomId1: '',
        listeningSchoolId2: '',
        listeningSchoolName2: '',
        listeningClassroomId2: '',
        listeningSchoolId3: '',
        listeningSchoolName3: '',
        listeningClassroomId3: '',
      },
      rules: {
        classRoomId: [{ required: true, message: '我校主讲教室不能为空', trigger: 'blur' }],
      },

      ourSchoolLectureRoom: [],
      listeningSchool1: [],
      listeningClassroom1: [],
      listeningSchool2: [],
      listeningClassroom2: [],
      listeningSchool3: [],
      listeningClassroom3: [],
    };
  },
  computed: {
    typeWord() {
      switch (this.optionType) {
        case DIALOG_TYPE_ADD:
          return '创建';
        case DIALOG_TYPE_EDIT:
          return '编辑';
        default:
          return '';
      }
    },
  },
  methods: {
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const getLectureRooms = () => {
            let schoolIds = [this.form.listeningSchoolId1, this.form.listeningSchoolId2, this.form.listeningSchoolId3];
            let classroomIds = [
              this.form.listeningClassroomId1,
              this.form.listeningClassroomId2,
              this.form.listeningClassroomId3,
            ];
            let lectureRooms = [];

            if (schoolIds.filter((x) => x !== '').length === 0) {
              this.$message.error('请选择听讲学校');
              return false;
            }
            if (classroomIds.filter((x) => x !== '').length === 0) {
              this.$message.error('请选择听讲教室');
              return false;
            }
            for (let index = 0; index < schoolIds.length; index++) {
              const item = schoolIds[index];

              if (item !== '') {
                if (classroomIds[index] === '') {
                  this.$message.error(`请选择听讲学校${index + 1}的听讲教室`);
                  return false;
                }

                lectureRooms.push({
                  schoolId: item,
                  classRoomId: classroomIds[index],
                });
              }
            }
            return lectureRooms;
          };

          let params = {
            classRoomId: this.form.classRoomId,
            lectureRooms: [],
          };
          let lectureRooms = getLectureRooms();

          if (lectureRooms === false) {
            return;
          }

          // edit
          if (this.pairGroupId !== '') {
            params.pairGroupId = this.pairGroupId;
          }

          params.lectureRooms = JSON.stringify(lectureRooms);

          saveOrUpdatePairGroup(params).then(() => {
            // console.log(res);
            this.$message.success(`${this.typeWord}结对组成功`);
            this.$emit('success');
          });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit('close');
    },
    getLectureRooms() {},
    // api 获取主讲教室
    getOurSchoolLectureRoom() {
      return getListClassrom({
        schoolId: this.$store.state.user.userInfo.schoolId,
      }).then(({ result }) => {
        this.ourSchoolLectureRoom = result;
      });
    },
    // api 获取听讲学校1列表
    getListeningSchool(query = '', type) {
      if (query && query.trim().length) {
        return getListSchool({
          schoolName: query,
        }).then(({ result }) => {
          this[`listeningSchool${type}`] = result;
        });
      } else {
        console.log(`listeningSchool${type}`, `listeningSchool${type}`);
        this.form[`listeningSchoolId${type}`] = '';
        this[`listeningSchool${type}`] = [];
        return Promise.resolve();
      }
    },
    remoteMethod1(query) {
      this.getListeningSchool(query, 1);
    },
    remoteMethod2(query) {
      this.getListeningSchool(query, 2);
    },
    remoteMethod3(query) {
      this.getListeningSchool(query, 3);
    },
    // api 获取听讲教室1列表
    getListeningClassrom1(val) {
      return getListClassrom({
        schoolId: val,
      }).then(({ result }) => {
        this.listeningClassroom1 = result;
      });
    },
    // api 获取听讲教室2列表
    getListeningClassrom2(val) {
      return getListClassrom({
        schoolId: val,
      }).then(({ result }) => {
        this.listeningClassroom2 = result;
      });
    },
    // api 获取听讲教室3列表
    getListeningClassrom3(val) {
      return getListClassrom({
        schoolId: val,
      }).then(({ result }) => {
        this.listeningClassroom3 = result;
      });
    },
  },
  created() {
    this.getOurSchoolLectureRoom('');
  },
  watch: {
    visible: {
      handler: async function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());

          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });

          // 编辑
          if (this.optionType === DIALOG_TYPE_EDIT) {
            this.isLoading = true;

            let promises = [
              this.getOurSchoolLectureRoom(),
              this.getListeningSchool(this.editParams.listeningSchoolName1, 1),
              this.getListeningSchool(this.editParams.listeningSchoolName2, 2),
              this.getListeningSchool(this.editParams.listeningSchoolName3, 3),
            ];

            this.editParams.listeningSchoolId1 &&
              promises.push(this.getListeningClassrom1(this.editParams.listeningSchoolId1));

            this.editParams.listeningSchoolId2 &&
              promises.push(this.getListeningClassrom2(this.editParams.listeningSchoolId2));

            this.editParams.listeningSchoolId3 &&
              promises.push(this.getListeningClassrom3(this.editParams.listeningSchoolId3));

            Promise.all(promises)
              .then(() => {
                this.form = Object.assign(this.form, this.editParams);

                // 需求：若删除了原来的教室，去掉选中id
                if (!this.listeningClassroom1.find((x) => x.id === this.form.listeningClassroomId1)) {
                  this.form.listeningClassroomId1 = '';
                }
                if (!this.listeningClassroom2.find((x) => x.id === this.form.listeningClassroomId2)) {
                  this.form.listeningClassroomId2 = '';
                }
                if (!this.listeningClassroom3.find((x) => x.id === this.form.listeningClassroomId3)) {
                  this.form.listeningClassroomId3 = '';
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.isLoading = true;
            Promise.all([this.getOurSchoolLectureRoom()]).finally(() => {
              this.isLoading = false;
            });
          }
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss">
.listGroup-dialog-select-popper {
  height: 304px;
  width: 380px;

  .select-input .el-input__inner {
    border: 0;
  }

  .el-select-dropdown__wrap {
    max-height: 304px;
    overflow-x: auto;
  }
}
</style>
